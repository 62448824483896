<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="ml-1"
        small
        style="margin-top: -1px"
        v-on="on"
        v-bind="attrs"
        >{{ icon }}</v-icon
      >
    </template>
    <p v-html="content"></p>
  </v-tooltip>
</template>
<script>
export default {
  name: "ToolTip",
  props: {
    content: {},
    icon: {
      default: () => "mdi-information",
    },
  },
};
</script>
